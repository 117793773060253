@font-face {
    font-family: "Inter";
    font-weight: 300;
    src: url(../fonts/Inter-Light.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 400;
    src: url(../fonts/Inter-Regular.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 500;
    src: url(../fonts/Inter-Medium.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 600;
    src: url(../fonts/Inter-SemiBold.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 700;
    src: url(../fonts/Inter-Bold.ttf);
}

@font-face {
    font-family: "Inter";
    font-weight: 800;
    src: url(../fonts/Inter-ExtraBold.ttf);
}
