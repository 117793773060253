.loading-wrapper {
  @apply h-[100px] w-[800px] overflow-hidden bg-white;
}

.loading-element {
  @apply flex h-full w-full items-center justify-center;
}

.loading-element:nth-child(1){
    animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 0.5s both;
}

.loading-element:nth-child(2){
    animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 1.5s both;
}

.loading-element:nth-child(3){
    animation: animate 1s cubic-bezier(0.19, 1, 0.22, 1) 2.5s both;
}

@keyframes animate {
  to {
    margin-top: -100px;
  }
}

