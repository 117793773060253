@tailwind base;
@tailwind components;
@tailwind utilities;

@import "fonts";

::-webkit-scrollbar {
  @apply h-1.5 w-1.5;
}

::-webkit-scrollbar-track {
  border-radius: 2px;
}

// ::-webkit-scrollbar-thumb {
//     @apply bg-typography2;
// }

// ::-webkit-scrollbar-thumb:hover {
//     @apply bg-typography1;
// }

@layer base {
  html {
    font-family: Inter;
  }
}

@layer utilities {
  [disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.dashboard-headline {
  @apply border mb-6 flex flex-col w-full min-h-[400px] relative  items-center rounded-2xl bg-cover px-10 py-16;
  background-image: url('../images/headline\ 1.png');
}

.label-required {
  &::before {
    content: '*';
    font-size: 12px;
    color: #dc2626;
    margin-right: 3px;
  }
}